
	import {
		ElMessage
	} from 'element-plus'
	import iconfont from '../components/iconfont.vue';
	export default {
		data() {
			return {
				isloading: false,
				ListData: [],
				edtdirect: 'rtl',
				editopinfo: false,
				editortitle: this.PAGELANG.fenleibianji,
				showdeldlg: false,
				delopt: "1",
				cates: [],
				optcates: [],
				edtprofile: {
					ID: 'add',
					Name: '',
					Remark: '',
					Status: true,
					ParentID: 0,
				},
				delformopt: {
					CateID: '0',
					DelOpt: '1',
					CntToCate: '0',	//DelpOpt=2时有效
					SubToCate: '0'
				}
			}
		},
		beforeCreate() {
			this.PAGELANG = this.LANG.pages.Category;
		},
		methods: {
			CloseDrawer() {
				this.editopinfo = false;
			},
			CloseDelDrawer() {
				this.showdeldlg = false;
			},
			Save() {
				console.log(this.edtprofile);
				this.axios.post(this.actions.savecate, this.edtprofile).then((response) => {
					console.log(response.data);
					if (response.data.errinfo == "OK") {
						this.$message.success(this.PAGELANG.caozuochenggong);
						this.$router.go(0);
					}
				});
			},
			Add(pid) {
				this.edtprofile = {
					ID: 'add',
					Name: '',
					Remark: '',
					Status: true,
					ParentID: 0,
				};
				//console.log(pid);
				this.RefreshDropdown();
				this.editopinfo = true;

				if (pid)
					this.edtprofile.ParentID = pid;
				else
					this.edtprofile.ParentID = '0';
				//console.log(this.edtprofile.ParentID);
			},
			Edit(id) {
				this.RefreshDropdown();
				if (!id) {
					ElMessage({
						message: this.PAGELANG.canshucuowu,
						type: 'error',
					});
				}

				this.axios.get(this.actions.catedetail, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						id: id,
					}
				}).then((response) => {
					console.log(response.data);
					if (response.data == 'paramerror') {
						ElMessage({
							message: this.PAGELANG.canshucuowu,
							type: 'error',
						});
					} else {
						this.edtprofile.ID = response.data.data.ID;
						this.edtprofile.Name = response.data.data.Name;
						this.edtprofile.Remark = response.data.data.Remark;
						this.edtprofile.ParentID = response.data.data.ParentID;

						console.log(this.edtprofile);
						this.RefreshDropdown();
						this.editopinfo = true;
					}

					//this.Refresh();
					//this.ListData = response.data;
				});
				console.log(id);
			},
			Move(op, row) {
				console.log(op);
				console.log(row);
				//row.OpName = '小小';
				//this.$refs['TreeList'].store.states.treeData.value[row.ParentID].loaded = false;
				//console.log(this.$refs['TreeList'].store);
				//console.log(this.$refs['TreeList'].store.states.treeData.value);
				//this.$refs['TreeList'].store.states.treeData.value[row.ID].loaded = false;
				//this.$refs['TreeList'].store.loadOrToggle(row);
				//console.log(this.$refs['TreeList'].store);
				this.axios.get(this.actions.movecate, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						id: row.ID,
						dir: op
					}
				}).then((response) => {
					console.log(response.data);
					if (response.data == 'paramserror') {
						ElMessage({
							message: this.PAGELANG.canshucuowu,
							type: 'error',
						});
					}
					if (response.data == 'movefailed' && op == 'up') {
						ElMessage({
							message: this.PAGELANG.wufashangyi,
							type: 'warning',
						});
					}
					if (response.data == 'movefailed' && op == 'down') {
						ElMessage({
							message: this.PAGELANG.wufaxiayi,
							type: 'warning',
						});
					}
					this.Refresh();
					//this.ListData = response.data;
				});
			},
			Delete(id) {
				console.log(id);
				this.delformopt = {
					CateID: id,
					DelOpt: '1',
					CntToCate: '0',	//DelpOpt=2时有效
					SubToCate: '0'
				};
				this.RefreshDelMvDropdown();
				this.showdeldlg = true;
			},
			DoDelete() {
				console.log(this.delformopt);
				this.axios.post(this.actions.delcate, this.delformopt).then((response) => {
						console.log(response.data);
						if (response.data.errinfo == "OK") {
							this.$message.success(this.PAGELANG.caozuochenggong);
							this.$router.go(0);
						}
					})
				
			},
			RefreshDelMvDropdown() {
				this.axios.get(this.actions.catelist, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: '0',
					}
				}).then((response) => {
					this.optcates = response.data;
					this.optcates.unshift({
						ID: '0',
						Name: '请选择分类',
						Remark: '请选择分类',
						Status: true,
						ParentID: 0,
						RootID: 0,
					});
				});

			},
			RefreshDropdown() {
				this.axios.get(this.actions.catelist, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: '0',
					}
				}).then((response) => {
					this.cates = response.data;
					this.cates.unshift({
						ID: '0',
						Name: '作为顶级分类',
						Remark: '作为顶级分类',
						Status: true,
						ParentID: 0,
						RootID: 0,
					});
				});

			},
			Refresh(pid) {
				if (!pid)
					pid = 0;
				this.isloading = true;
				this.axios.get(this.actions.catelist, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						pid: pid,
					}
				}).then((response) => {
					this.isloading = false;
					console.log(response.data);
					this.ListData = response.data;
				});

			},
			UpdateStatus(id, stat) {
				console.log(id, stat);
				if(!id || !stat)
					return;
				this.isloading = true;
				this.axios.get(this.actions.catestat, {
					headers: {
						"Content-Type": "application/json",
					},
					params: {
						cid: id,
						stat: stat
					}
				}).then((response) => {
					this.isloading = false;
					console.log(response.data);
					if(response.data.retinfo == 'ok') {
						console.log('ok')
					}
				});
			},
			formatDate(date) {
				var datetime = new Date(date * 1000);
				var year = datetime.getFullYear();
				var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
				var day = ("0" + datetime.getDate()).slice(-2);
				return year + "-" + month + "-" + day;
			}
		},
		created: function() {
			this.Refresh();
		},
		components: {
			iconfont,
		}
	}
